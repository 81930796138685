import React from 'react'
import { Grid, Icon, IconProps, Table } from 'semantic-ui-react'

import { procsea, useBreakpoints } from '@procsea/design-system'

import { useUser } from 'src/contexts/userContext'
import { UINotification } from 'src/types'

import './Notifications.css'

const getCorrectIcon = (type: UINotification['type']): IconProps['name'] | '' => {
  switch (type) {
    case 'informative':
      return 'info'
    case 'positive':
      return 'check'
    case 'negative':
      return 'warning sign'
    default:
      return ''
  }
}
const getCorrectColor = (type: UINotification['type']) => {
  switch (type) {
    case 'informative':
      return 'background-lightBlue'
    case 'positive':
      return 'background-lightGreen'
    case 'negative':
      return 'background-lightRed'
    default:
      return ''
  }
}

const FOUR_SECONDS_NOTIFICATION = 4000

export interface NotificationsProps {
  notifications: UINotification[]
  handleNotificationClose: (uuid: string) => void
}

const Notifications = ({ notifications = [], handleNotificationClose }: NotificationsProps) => {
  const { isDesktop, isMobile, isTablet } = useBreakpoints()
  const user = useUser()
  if (notifications.length === 0) return null

  const desktopMenuWidth = procsea.menu.dimensions.desktop.width

  const tabletMenuWidth = '0'

  const menuWidth = user ? (isDesktop ? desktopMenuWidth : tabletMenuWidth) : '100%'

  return (
    <div className="notifications-container" data-e2e="notifications-container">
      <Grid>
        <Grid.Row>
          {!(isMobile || isTablet) && <Grid.Column style={{ flex: `0 0 ${menuWidth}` }} />}
          <Grid.Column style={{ flex: '1 1 auto' }}>
            {notifications.map(notification => {
              if (notification.type === 'positive')
                setTimeout(
                  () => handleNotificationClose(notification.id),
                  FOUR_SECONDS_NOTIFICATION
                )
              return (
                <Table key={notification.id} padded unstackable>
                  <Table.Body>
                    <Table.Row
                      className={`notification-container notification-${
                        notification.type
                      } ${getCorrectColor(notification.type)}`}
                    >
                      <Table.Cell collapsing>
                        <Icon
                          className="notification-icon"
                          name={getCorrectIcon(notification.type) as IconProps['name']}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <h4 className="notification-message" data-e2e="notification-message">
                          {notification.message}
                        </h4>
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Icon
                          data-e2e="notification-close"
                          className="notification-close"
                          name="close"
                          onClick={() => handleNotificationClose(notification.id)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )
            })}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default Notifications
