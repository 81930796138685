import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearNotification } from 'src/action-creators/notifications'
import Notifications from 'src/components/base/notifications/Notifications'
import { RootState } from 'src/store/initialStore'

const NotificationsContainer = () => {
  const dispatch = useDispatch()

  const notifications = useSelector((state: RootState) => state.notifications)

  const dispatchClearNotification = useCallback(
    (uuid: string) => dispatch(clearNotification(uuid)),
    [dispatch]
  )

  return (
    <Notifications
      handleNotificationClose={dispatchClearNotification}
      notifications={notifications}
    />
  )
}

export default NotificationsContainer
